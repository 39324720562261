*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 62.5%;
  box-sizing: border-box; }
  @media only screen and (max-width: 68.75em) {
    html {
      font-size: 50%; } }
  @media only screen and (max-width: 56.25em) {
    html {
      font-size: 40%; } }
  @media only screen and (max-width: 50em) {
    html {
      font-size: 38%; } }

body {
  font-family: Open Sans;
  font-weight: 400;
  color: #111111;
  line-height: 1.6;
  margin: 0 auto;
  background-color: #6C7F8E;
  max-width: 1420px;
  overflow-x: hidden; }

.header {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 1 / 2;
  padding-bottom: 6rem;
  display: grid;
  grid-template-columns: max-content minmax(20rem, 1fr);
  align-items: center; }
  @media only screen and (max-width: 75em) {
    .header {
      grid-template-columns: max-content 1fr; } }
  @media only screen and (max-width: 56.25em) {
    .header {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, max-content); } }
  @media only screen and (max-width: 43.75em) {
    .header {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr)); } }

.display {
  background-color: #FFFFFF;
  padding-bottom: 4%;
  grid-column: center-start / center-end;
  grid-row: 2 / 3;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(700px, max-content));
  grid-row: auto; }
  @media only screen and (max-width: 87.5em) {
    .display {
      grid-template-columns: repeat(2, 1fr); } }
  @media only screen and (max-width: 56.25em) {
    .display {
      padding-top: 5%; } }
  @media only screen and (max-width: 43.75em) {
    .display {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 1fr);
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 1%;
      padding-right: 1%; } }
  .display__text {
    background-image: url(../img/displaybkg.png);
    background-repeat: no-repeat;
    background-position: 0% 53%;
    display: grid;
    grid-template-columns: 1fr;
    padding-left: 13%; }
    @media only screen and (max-width: 56.25em) {
      .display__text {
        background-size: 60%; } }
    @media only screen and (max-width: 43.75em) {
      .display__text {
        grid-row: 1 / 2;
        background-size: 30%;
        padding-left: 0;
        grid-template-rows: repeat(4, 80px);
        justify-items: center; } }
  .display__heading {
    position: relative;
    z-index: 1;
    margin-top: 0;
    transform: translate(0%, -5%); }
    @media only screen and (max-width: 87.5em) {
      .display__heading {
        padding-left: 0; } }
    @media only screen and (max-width: 75em) {
      .display__heading {
        padding-left: 0; } }
    @media only screen and (max-width: 56.25em) {
      .display__heading {
        padding-left: 0;
        transform: translate(0%, 0%); } }
    @media only screen and (max-width: 43.75em) {
      .display__heading {
        max-width: 100%;
        padding: 0%;
        text-align: center; } }
    @media only screen and (max-width: 37.5em) {
      .display__heading {
        max-width: 100%;
        padding: 0;
        text-align: center; } }
  .display__text-small {
    margin-bottom: 4rem;
    max-width: 74%; }
    @media only screen and (max-width: 56.25em) {
      .display__text-small {
        max-width: 300px;
        padding-left: 0; } }
    @media only screen and (max-width: 43.75em) {
      .display__text-small {
        max-width: 100%;
        margin-bottom: 0; } }
    @media only screen and (max-width: 37.5em) {
      .display__text-small {
        max-width: 100%;
        padding: 0 5%;
        text-align: center; } }
    @media only screen and (max-width: 25em) {
      .display__text-small {
        max-width: 100%;
        padding: 0 5%;
        text-align: center; } }
  .display__img {
    transform: translate(-11%, 0);
    width: 100%;
    height: auto; }
    @media only screen and (max-width: 87.5em) {
      .display__img {
        width: 90%;
        transform: translate(-11, 0); } }
    @media only screen and (max-width: 75em) {
      .display__img {
        width: 100%;
        transform: translate(-9%, 0);
        overflow: hidden; } }
    @media only screen and (max-width: 56.25em) {
      .display__img {
        width: 80%;
        transform: translate(0%, 0); } }
    @media only screen and (max-width: 50em) {
      .display__img {
        width: 100%;
        transform: translate(-9%, 0); } }
    @media only screen and (max-width: 43.75em) {
      .display__img {
        width: 100%;
        transform: translate(0, 0); } }
  .display__line {
    margin-top: 4rem;
    width: 110px;
    transform: translateX(-1%);
    height: 6px;
    background-color: #6C7F8E; }
    @media only screen and (max-width: 50em) {
      .display__line {
        transform: translateX(0); } }
    @media only screen and (max-width: 43.75em) {
      .display__line {
        transform: translateX(0);
        margin-top: 0; } }
    @media only screen and (max-width: 37.5em) {
      .display__line {
        transform: translateX(0); } }
    @media only screen and (max-width: 25em) {
      .display__line {
        transform: translateX(0); } }
  @media only screen and (max-width: 43.75em) {
    .display__box {
      grid-row: 2 / 3;
      display: grid;
      justify-items: center;
      padding-top: 50px; } }

.strategy {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 3 / 4;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50rem, 1fr)); }
  @media only screen and (max-width: 43.75em) {
    .strategy {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, max-content); } }
  .strategy__header {
    padding-left: 13%;
    margin-bottom: 2%; }
    @media only screen and (max-width: 75em) {
      .strategy__header {
        padding-left: 20%;
        margin-top: 15%; } }
    @media only screen and (max-width: 43.75em) {
      .strategy__header {
        padding-left: 0; } }
  .strategy__content {
    padding-left: 13%;
    max-width: 65%; }
    @media only screen and (max-width: 43.75em) {
      .strategy__content {
        padding-left: 0; } }
  .strategy__container {
    border: none;
    color: #FFFFFF;
    background-color: #6C7F8E;
    margin: 8% 13%;
    display: grid;
    grid-template-columns: min-content 1fr;
    padding-bottom: 50px;
    padding-top: 80px;
    font-family: Libre Baskerville, serif; }
    @media only screen and (max-width: 87.5em) {
      .strategy__container {
        padding-right: 40px; } }
  .strategy__icon {
    font-size: 2rem;
    padding-left: 4rem; }
    @media only screen and (max-width: 43.75em) {
      .strategy__icon {
        padding-left: 8rem; } }
  .strategy__steps {
    padding-left: 4rem; }
  .strategy__nums {
    font-size: 3rem; }
  .strategy__info {
    font-size: 1.6rem;
    font-weight: 400;
    padding-bottom: 3.5rem;
    font-family: Open Sans; }
  .strategy__info1 {
    font-size: 1.6rem;
    font-weight: 300;
    padding-bottom: 3.5rem;
    font-family: Open Sans; }
  @media only screen and (max-width: 43.75em) {
    .strategy__text {
      display: grid;
      justify-items: center; } }
  @media only screen and (max-width: 43.75em) {
    .strategy__steps {
      display: grid;
      justify-items: center; } }

.embrace {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 4 / 5;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, auto);
  grid-gap: 1.5rem;
  align-items: center; }
  @media only screen and (max-width: 37.5em) {
    .embrace {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(8, auto); } }
  .embrace__pic1 {
    grid-row: 1 / 2;
    grid-column: 1 / 2; }
    @media only screen and (max-width: 37.5em) {
      .embrace__pic1 {
        grid-row: 1 / 2;
        grid-column: 1 / 2; } }
    .embrace__pic1-img {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
      width: 100%;
      height: auto;
      object-fit: cover; }
      @media only screen and (max-width: 37.5em) {
        .embrace__pic1-img {
          width: 100%;
          height: 200px; } }
    .embrace__pic1-num {
      height: 9rem;
      width: 9rem;
      background-color: #AF7F43;
      border-radius: 0;
      display: block;
      text-align: center;
      color: #FFFFFF;
      font-size: 3.6rem;
      padding-top: 18px;
      text-anchor: middle;
      font-family: Libre Baskerville, serif;
      position: absolute;
      font-weight: 700;
      transform: translate(-208%, -170%); }
      @media only screen and (max-width: 50em) {
        .embrace__pic1-num {
          height: 9.5rem;
          width: 9rem;
          font-size: 2.6rem; } }
      @media only screen and (max-width: 43.75em) {
        .embrace__pic1-num {
          transform: translate(-208%, -200%); } }
      @media only screen and (max-width: 37.5em) {
        .embrace__pic1-num {
          margin-left: 50%;
          transform: translate(-208%, -320%); } }
      @media only screen and (max-width: 31.25em) {
        .embrace__pic1-num {
          margin-left: 50%;
          transform: translate(-208%, -370%); } }
      @media only screen and (max-width: 25em) {
        .embrace__pic1-num {
          margin-left: 50%;
          transform: translate(-208%, -400%); } }
  .embrace__content1 {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    padding-left: 14rem;
    padding-right: 10rem; }
    @media only screen and (max-width: 37.5em) {
      .embrace__content1 {
        grid-row: 2 / 3;
        grid-column: 1 / 2;
        padding-top: 10rem;
        padding-bottom: 10rem; } }
  .embrace__pic2 {
    grid-column: 2 / 3;
    grid-row: 2 / 3; }
    @media only screen and (max-width: 37.5em) {
      .embrace__pic2 {
        grid-row: 3 / 4;
        grid-column: 1 / 2; } }
    .embrace__pic2-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      vertical-align: bottom; }
      @media only screen and (max-width: 37.5em) {
        .embrace__pic2-img {
          width: 100%;
          height: 200px; } }
    .embrace__pic2-num {
      height: 9rem;
      width: 9rem;
      background-color: #AF7F43;
      border-radius: 0;
      display: block;
      text-align: center;
      color: #FFFFFF;
      font-size: 3.6rem;
      padding-top: 18px;
      text-anchor: middle;
      font-family: Libre Baskerville, serif;
      position: absolute;
      font-weight: 700;
      transform: translate(-58%, -310%); }
      @media only screen and (max-width: 50em) {
        .embrace__pic2-num {
          height: 9.5rem;
          width: 9rem;
          font-size: 2.6rem;
          transform: translate(-58%, -280%); } }
      @media only screen and (max-width: 43.75em) {
        .embrace__pic2-num {
          transform: translate(-58%, -240%); } }
      @media only screen and (max-width: 37.5em) {
        .embrace__pic2-num {
          margin-left: 50%;
          transform: translate(-58%, -50%); } }
  .embrace__content2 {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    padding-left: 10rem;
    padding-right: 14rem;
    background-color: #dae7f1;
    align-self: stretch;
    padding-top: 25%; }
    @media only screen and (max-width: 75em) {
      .embrace__content2 {
        padding-top: 15%; } }
    @media only screen and (max-width: 68.75em) {
      .embrace__content2 {
        padding-top: 20%; } }
    @media only screen and (max-width: 43.75em) {
      .embrace__content2 {
        padding-top: 40px;
        padding-right: 50px; } }
    @media only screen and (max-width: 37.5em) {
      .embrace__content2 {
        grid-row: 4 / 5;
        grid-column: 1 / 2;
        padding-top: 10rem;
        padding-bottom: 10rem; } }
  .embrace__pic3 {
    grid-column: 1 / 2;
    grid-row: 3 / 4; }
    @media only screen and (max-width: 37.5em) {
      .embrace__pic3 {
        grid-row: 5 / 6;
        grid-column: 1 / 2; } }
    .embrace__pic3-img {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
      width: 100%;
      height: auto;
      object-fit: cover; }
      @media only screen and (max-width: 37.5em) {
        .embrace__pic3-img {
          width: 100%;
          height: 200px; } }
    .embrace__pic3-num {
      height: 9rem;
      width: 9rem;
      background-color: #AF7F43;
      border-radius: 0;
      display: block;
      text-align: center;
      color: #FFFFFF;
      font-size: 3.6rem;
      padding-top: 18px;
      text-anchor: middle;
      font-family: Libre Baskerville, serif;
      position: absolute;
      font-weight: 700;
      transform: translate(745%, -310%);
      transform: translate(-208%, -170%); }
      @media only screen and (max-width: 50em) {
        .embrace__pic3-num {
          height: 9.5rem;
          width: 9rem;
          font-size: 2.6rem; } }
      @media only screen and (max-width: 37.5em) {
        .embrace__pic3-num {
          margin-left: 50%;
          transform: translate(-208%, -320%); } }
      @media only screen and (max-width: 25em) {
        .embrace__pic3-num {
          margin-left: 50%;
          transform: translate(-208%, -345%); } }
  .embrace__content3 {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    padding-left: 14rem;
    padding-right: 10rem; }
    @media only screen and (max-width: 37.5em) {
      .embrace__content3 {
        grid-row: 6 / 7;
        grid-column: 1 / 2;
        padding-top: 10rem;
        padding-bottom: 10rem; } }
  .embrace__pic4 {
    grid-column: 2 / 3;
    grid-row: 4 / 5; }
    @media only screen and (max-width: 37.5em) {
      .embrace__pic4 {
        grid-row: 7 / 8;
        grid-column: 1 / 2; } }
    .embrace__pic4-img {
      width: 100%;
      height: auto;
      object-fit: cover;
      vertical-align: bottom; }
      @media only screen and (max-width: 37.5em) {
        .embrace__pic4-img {
          width: 100%;
          height: 200px; } }
    .embrace__pic4-num {
      height: 9rem;
      width: 9rem;
      background-color: #AF7F43;
      border-radius: 0;
      display: block;
      text-align: center;
      color: #FFFFFF;
      font-size: 3.6rem;
      padding-top: 18px;
      text-anchor: middle;
      font-family: Libre Baskerville, serif;
      position: absolute;
      font-weight: 700;
      transform: translate(-58%, -310%); }
      @media only screen and (max-width: 50em) {
        .embrace__pic4-num {
          height: 9.5rem;
          width: 9rem;
          font-size: 2.6rem;
          transform: translate(-58%, -280%); } }
      @media only screen and (max-width: 37.5em) {
        .embrace__pic4-num {
          margin-left: 50%;
          transform: translate(-58%, -50%); } }
  .embrace__content4 {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
    padding-left: 10rem;
    padding-right: 14rem;
    background-color: #dae7f1;
    align-self: stretch;
    padding-top: 25%; }
    @media only screen and (max-width: 75em) {
      .embrace__content4 {
        padding-top: 15%; } }
    @media only screen and (max-width: 68.75em) {
      .embrace__content4 {
        padding-top: 20%; } }
    @media only screen and (max-width: 43.75em) {
      .embrace__content4 {
        padding-top: 30px;
        padding-right: 50px; } }
    @media only screen and (max-width: 37.5em) {
      .embrace__content4 {
        grid-row: 8 / 9;
        grid-column: 1 / 2;
        padding-top: 10rem;
        padding-bottom: 10rem; } }

.specialty {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 5 / 6;
  padding-bottom: 10rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(6, 1fr); }
  @media only screen and (max-width: 37.5em) {
    .specialty {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(6, auto); } }
  .specialty__header {
    grid-column: 1 / -1;
    grid-row: 1 / 2;
    padding-top: 10rem;
    padding-left: 10rem; }
    @media only screen and (max-width: 37.5em) {
      .specialty__header {
        justify-self: center;
        padding-left: 0;
        padding-top: 8rem;
        padding-bottom: 8rem; } }
  .specialty__services {
    grid-column: 1 / -1;
    grid-row: 2 / 6;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center; }
    @media only screen and (max-width: 37.5em) {
      .specialty__services {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, auto); } }
    .specialty__services-1 {
      grid-column: 1 / 2;
      padding: 0 10rem;
      display: grid;
      grid-template-rows: 1fr min-content 1fr;
      justify-items: center; }
      @media only screen and (max-width: 75em) {
        .specialty__services-1 {
          padding: 0 5rem; } }
      @media only screen and (max-width: 37.5em) {
        .specialty__services-1 {
          grid-column: 1 / 2;
          grid-row: 1 / 2; } }
    .specialty__services-2 {
      grid-column: 2 / 3;
      padding: 0 10rem;
      display: grid;
      grid-template-rows: 1fr min-content 1fr;
      justify-items: center; }
      @media only screen and (max-width: 75em) {
        .specialty__services-2 {
          padding: 0 5rem; } }
      @media only screen and (max-width: 37.5em) {
        .specialty__services-2 {
          grid-column: 1 / 2;
          grid-row: 2 / 3; } }
    .specialty__services-3 {
      grid-column: 3 / -1;
      padding: 0 10rem;
      display: grid;
      grid-template-rows: 1fr min-content 1fr;
      justify-items: center; }
      @media only screen and (max-width: 75em) {
        .specialty__services-3 {
          padding: 0 5rem; } }
      @media only screen and (max-width: 37.5em) {
        .specialty__services-3 {
          grid-column: 1 / 2;
          grid-row: 3 / 4; } }
  .specialty__button {
    grid-column: 2 / 3;
    grid-row: 6 / 7;
    justify-self: center;
    align-self: center;
    margin-top: 4.5rem; }

.service__text {
  text-align: center; }

.service__img {
  width: 20rem;
  height: auto;
  display: grid;
  align-self: center; }
  @media only screen and (max-width: 37.5em) {
    .service__img {
      width: 10rem; } }

.footer {
  background-color: #6C7F8E;
  grid-column: center-start / center-end;
  display: grid;
  grid-template-columns: 30% 0 70%;
  padding-top: 4rem;
  margin-bottom: 10rem; }
  @media only screen and (max-width: 87.5em) {
    .footer {
      display: grid;
      grid-template-columns: 30% 0 70%;
      padding-top: 4rem; } }
  @media only screen and (max-width: 50em) {
    .footer {
      display: grid;
      grid-template-columns: 30% 0 70%;
      padding-top: 4rem;
      margin-bottom: 5rem; } }
  @media only screen and (max-width: 37.5em) {
    .footer {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, auto);
      grid-row-gap: 4rem;
      margin-bottom: 4rem; } }
  .footer__contact {
    grid-column: 1 / 2;
    display: grid;
    padding-left: 5rem;
    grid-template-columns: 40% 1fr; }
    @media only screen and (max-width: 75em) {
      .footer__contact {
        padding-left: 1rem; } }
    @media only screen and (max-width: 37.5em) {
      .footer__contact {
        grid-column: 1 / 2;
        grid-row: 1 / 2; } }
    @media only screen and (max-width: 37.5em) {
      .footer__contact {
        padding-left: 5rem;
        justify-items: center; } }
    .footer__contact a:link,
    .footer__contact a:active,
    .footer__contact a:visited {
      outline: none; }
    .footer__contact-logo {
      width: 12rem;
      border: 0; }
    .footer__contact--info {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, auto); }
      .footer__contact--info-header {
        color: #FFFFFF;
        font-size: 1.6rem;
        font-weight: 400;
        align-self: end; }
      .footer__contact--info-text {
        font-size: 1.3rem;
        font-weight: 300;
        color: #FFFFFF;
        align-self: center; }
      .footer__contact--info-phone {
        font-size: 1.3rem;
        font-weight: 300;
        color: #FFFFFF; }
  .footer__nav {
    grid-column: 3 / 4;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(2, auto);
    grid-row-gap: 2rem; }
    @media only screen and (max-width: 43.75em) {
      .footer__nav {
        justify-self: stretch; } }
    @media only screen and (max-width: 37.5em) {
      .footer__nav {
        grid-column: 1 / 2;
        grid-row: 2 / 3; } }
    .footer__nav-nav {
      list-style: none;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
      justify-items: center;
      align-items: center; }
      @media only screen and (max-width: 87.5em) {
        .footer__nav-nav {
          grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr)); } }
      @media only screen and (max-width: 43.75em) {
        .footer__nav-nav {
          grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr)); } }
      @media only screen and (max-width: 37.5em) {
        .footer__nav-nav {
          grid-template-columns: repeat(5, 1fr);
          justify-self: center;
          padding-left: 0;
          padding-bottom: 2rem;
          grid-column-gap: 3rem; } }
      @media only screen and (max-width: 31.25em) {
        .footer__nav-nav {
          grid-template-columns: repeat(5, 1fr);
          justify-self: center;
          padding-left: 0;
          padding-bottom: 2rem;
          grid-column-gap: 2rem; } }
      @media only screen and (max-width: 25em) {
        .footer__nav-nav {
          grid-template-columns: repeat(5, 1fr);
          justify-self: center;
          padding-left: 0;
          padding-bottom: 2rem;
          grid-column-gap: 0; } }
      .footer__nav-nav a:link,
      .footer__nav-nav a:visited {
        color: #FFFFFF;
        text-decoration: none;
        font-size: 1.3rem;
        font-weight: 600; }
    .footer__nav-copy {
      color: #FFFFFF;
      font-size: 1.6rem;
      font-weight: 400;
      padding-right: 6%;
      justify-self: end;
      align-self: center; }
      @media only screen and (max-width: 37.5em) {
        .footer__nav-copy {
          justify-self: center;
          padding-right: 0; } }
      @media only screen and (max-width: 25em) {
        .footer__nav-copy {
          justify-self: center;
          padding-left: 0;
          padding-bottom: 2rem; } }

.slide {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 2 / 3;
  padding-top: 5%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(700px, 1fr));
  grid-row: auto; }
  @media only screen and (max-width: 87.5em) {
    .slide {
      grid-template-columns: repeat(2, auto); } }
  @media only screen and (max-width: 43.75em) {
    .slide {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, max-content);
      padding-top: 0;
      justify-self: center; } }
  @media only screen and (max-width: 25em) {
    .slide {
      grid-template-rows: repeat(2, auto); } }
  .slide__header {
    padding-left: 10%; }
    @media only screen and (max-width: 43.75em) {
      .slide__header {
        padding: 0%;
        justify-self: center; } }
    @media only screen and (max-width: 25em) {
      .slide__header {
        padding: 0%;
        justify-self: center;
        text-align: center; } }
  .slide__text {
    padding-left: 10%; }
    @media only screen and (max-width: 43.75em) {
      .slide__text {
        padding-left: 0;
        justify-self: center; } }
    @media only screen and (max-width: 25em) {
      .slide__text {
        padding: 0%;
        justify-self: center;
        text-align: center; } }
    @media only screen and (max-width: 43.75em) {
      .slide__text p {
        font-size: 2.5rem; } }
    @media only screen and (max-width: 31.25em) {
      .slide__text p {
        font-size: 1.8rem; } }
    @media only screen and (max-width: 25em) {
      .slide__text p {
        font-size: 1.8rem; } }
  .slide__box {
    padding-right: 13%; }
    @media only screen and (max-width: 43.75em) {
      .slide__box {
        padding-left: 20%;
        justify-self: center; } }
  .slide__img {
    width: 100%;
    height: 100%;
    transform: translateY(-20%); }

.services {
  background-color: #fef3e6;
  padding-top: 2rem;
  padding-bottom: 4rem;
  grid-column: center-start / center-end;
  grid-row: 3 / 4;
  display: grid;
  grid-template-columns: repeat(3, 1fr); }
  @media only screen and (max-width: 75em) {
    .services {
      grid-template-columns: repeat(3, auto); } }
  @media only screen and (max-width: 31.25em) {
    .services {
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: repeat(3, auto);
      grid-row-gap: 2rem; } }
  .services__one {
    grid-column: 1 / 2;
    padding: 0 10rem;
    display: grid;
    grid-template-rows: 1fr min-content 1fr;
    justify-items: center; }
    @media only screen and (max-width: 75em) {
      .services__one {
        padding: 0 4rem; } }
    @media only screen and (max-width: 31.25em) {
      .services__one {
        grid-column: 1 / 2;
        grid-row: 1 / 2; } }
  .services__two {
    grid-column: 2 / 3;
    padding: 0 10rem;
    display: grid;
    grid-template-rows: 1fr min-content 1fr;
    justify-items: center; }
    @media only screen and (max-width: 75em) {
      .services__two {
        padding: 0 4rem; } }
    @media only screen and (max-width: 31.25em) {
      .services__two {
        grid-column: 1 / 2;
        grid-row: 2 / 3; } }
  .services__three {
    grid-column: 3 / 4;
    padding: 0 10rem;
    display: grid;
    grid-template-rows: 1fr min-content 1fr;
    justify-items: center; }
    @media only screen and (max-width: 75em) {
      .services__three {
        padding: 0 4rem; } }
    @media only screen and (max-width: 31.25em) {
      .services__three {
        grid-column: 1 / 2;
        grid-row: 3 / 4; } }
  .services__img {
    width: 7.9rem;
    height: auto;
    display: grid;
    align-self: center; }
  .services__text {
    text-align: center; }

.procedure {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 4 / 5;
  display: grid;
  grid-template-rows: repeat(2, auto); }
  .procedure__header {
    padding: 10rem;
    text-align: center; }
    @media only screen and (max-width: 31.25em) {
      .procedure__header {
        padding: 5rem; } }
    @media only screen and (max-width: 25em) {
      .procedure__header {
        padding: 5rem; } }
  .procedure__steps {
    padding: 5rem 13%;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: repeat(5 auto);
    grid-column-gap: 10rem; }
    @media only screen and (max-width: 37.5em) {
      .procedure__steps {
        margin-bottom: 3rem;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(7, auto);
        justify-items: center;
        grid-row-gap: 3rem; } }
    .procedure__steps-num1 {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      height: 7.5rem;
      width: 7.5rem;
      background-color: #a6d1f2;
      border-radius: 50%;
      display: block;
      text-align: center;
      color: #1B75BC;
      font-size: 5rem;
      text-anchor: middle;
      font-family: Open Sans; }
      @media only screen and (max-width: 75em) {
        .procedure__steps-num1 {
          transform: translateY(50%); } }
      @media only screen and (max-width: 37.5em) {
        .procedure__steps-num1 {
          grid-column: 1 / 2;
          grid-row: 1 / 2;
          transform: translateY(0%); } }
    .procedure__steps-text1 {
      grid-column: 2 / 3;
      grid-row: 1 / 2; }
      @media only screen and (max-width: 75em) {
        .procedure__steps-text1 {
          transform: translateY(30%); } }
      @media only screen and (max-width: 37.5em) {
        .procedure__steps-text1 {
          grid-column: 1 / 2;
          grid-row: 2 / 3;
          transform: translateY(0%); } }
    .procedure__steps-line1 {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
      border-left: solid 4px #a6d1f2;
      height: 16rem;
      margin-left: 50%; }
      @media only screen and (max-width: 37.5em) {
        .procedure__steps-line1 {
          display: none; } }
    .procedure__steps-empty1 {
      grid-column: 2 / 3;
      grid-row: 2 / 3; }
      @media only screen and (max-width: 37.5em) {
        .procedure__steps-empty1 {
          display: none; } }
    @media only screen and (max-width: 37.5em) {
      .procedure__steps-empty2 {
        display: none; } }
    .procedure__steps-num2 {
      grid-column: 1 / 2;
      grid-row: 3 / 4;
      align-self: center;
      height: 7.5rem;
      width: 7.5rem;
      background-color: #a6d1f2;
      border-radius: 50%;
      display: block;
      text-align: center;
      color: #1B75BC;
      font-size: 5rem;
      text-anchor: middle;
      font-family: font-primary;
      transform: translateY(-20%); }
      @media only screen and (max-width: 75em) {
        .procedure__steps-num2 {
          transform: translateY(-50%); } }
      @media only screen and (max-width: 37.5em) {
        .procedure__steps-num2 {
          grid-column: 1 / 2;
          grid-row: 3 / 4;
          transform: translateY(0%); } }
    .procedure__steps-text2 {
      grid-column: 2 / 3;
      grid-row: 3 / 4;
      transform: translateY(-20%); }
      @media only screen and (max-width: 68.75em) {
        .procedure__steps-text2 {
          transform: translateY(-40%); } }
      @media only screen and (max-width: 37.5em) {
        .procedure__steps-text2 {
          grid-column: 1 / 2;
          grid-row: 4 / 5;
          transform: translateY(0%); } }
    .procedure__steps-line2 {
      grid-column: 1 / 2;
      grid-row: 4 / 5;
      border-left: solid 2px #a6d1f2;
      border-left: solid 4px #a6d1f2;
      height: 16rem;
      margin-left: 50%;
      transform: translateY(-20%); }
      @media only screen and (max-width: 75em) {
        .procedure__steps-line2 {
          transform: translateY(-50%); } }
      @media only screen and (max-width: 68.75em) {
        .procedure__steps-line2 {
          transform: translateY(-42%); } }
      @media only screen and (max-width: 37.5em) {
        .procedure__steps-line2 {
          display: none; } }
    .procedure__steps-num3 {
      grid-column: 1 / 2;
      grid-row: 5 / 6;
      height: 7.5rem;
      width: 7.5rem;
      background-color: #a6d1f2;
      border-radius: 50%;
      display: block;
      text-align: center;
      color: #1B75BC;
      font-size: 5rem;
      text-anchor: middle;
      font-family: font-primary;
      transform: translateY(-43%); }
      @media only screen and (max-width: 75em) {
        .procedure__steps-num3 {
          transform: translateY(-120%); } }
      @media only screen and (max-width: 68.75em) {
        .procedure__steps-num3 {
          transform: translateY(-130%); } }
      @media only screen and (max-width: 37.5em) {
        .procedure__steps-num3 {
          grid-column: 1 / 2;
          grid-row: 5 / 6;
          transform: translateY(0%); } }
    .procedure__steps-text3 {
      grid-column: 2 / 3;
      grid-row: 5 / 6;
      transform: translateY(-43%); }
      @media only screen and (max-width: 75em) {
        .procedure__steps-text3 {
          transform: translateY(-90%); } }
      @media only screen and (max-width: 68.75em) {
        .procedure__steps-text3 {
          transform: translateY(-120%); } }
      @media only screen and (max-width: 37.5em) {
        .procedure__steps-text3 {
          grid-column: 1 / 2;
          grid-row: 6 / 7;
          transform: translateY(0%); } }
  @media only screen and (max-width: 31.25em) {
    .procedure h1 {
      font-size: 2.0rem; } }
  @media only screen and (max-width: 25em) {
    .procedure h1 {
      font-size: 2.0rem; } }
  .procedure__highlight {
    color: #FFFFFF;
    background-color: #F7941D; }

.offers {
  padding: 4%;
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 5 / 6;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
  grid-template-rows: auto;
  grid-column-gap: 3rem; }
  @media only screen and (max-width: 75em) {
    .offers {
      padding: 0 1rem; } }
  @media only screen and (max-width: 25em) {
    .offers {
      padding: 0; } }
  @media only screen and (max-width: 75em) {
    .offers {
      grid-template-columns: repeat(3, auto);
      grid-column-gap: 1rem; } }
  @media only screen and (max-width: 43.75em) {
    .offers {
      grid-template-columns: auto;
      grid-template-rows: repeat(3, auto);
      grid-row-gap: 3rem;
      justify-items: center;
      grid-column-gap: 0; } }
  .offers__dynamic {
    border: 1px solid #1B75BC;
    border-radius: 5px;
    padding: 4rem;
    display: grid;
    grid-template-rows: repeat(13, 1fr);
    justify-items: center;
    align-items: center; }
    @media only screen and (max-width: 43.75em) {
      .offers__dynamic {
        grid-template-columns: 300px;
        justify-items: center; } }
    @media only screen and (max-width: 25em) {
      .offers__dynamic {
        grid-template-columns: 250px;
        justify-items: center; } }
    .offers__dynamic-icon {
      width: 5.5rem; }
    .offers__dynamic-field {
      word-wrap: normal; }
  .offers__progressive {
    border: 1px solid #F7941D;
    border-radius: 5px;
    padding: 4rem;
    display: grid;
    grid-template-rows: repeat(13, 1fr);
    justify-items: center;
    align-items: center; }
    @media only screen and (max-width: 43.75em) {
      .offers__progressive {
        grid-template-columns: 300px;
        justify-items: center; } }
    @media only screen and (max-width: 25em) {
      .offers__progressive {
        grid-template-columns: 250px;
        justify-items: center; } }
  .offers__enterprise {
    border: 1px solid #FF673E;
    border-radius: 5px;
    padding: 4rem;
    display: grid;
    grid-template-rows: repeat(13, 1fr);
    justify-items: center;
    align-items: center; }
    @media only screen and (max-width: 43.75em) {
      .offers__enterprise {
        grid-template-columns: 300px;
        justify-items: center; } }
    @media only screen and (max-width: 25em) {
      .offers__enterprise {
        grid-template-columns: 250px;
        justify-items: center; } }

.testimonial {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 6 / 7;
  display: grid;
  grid-template-rows: repeat(3, auto); }
  .testimonial__header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    justify-items: center;
    align-items: center; }
    .testimonial__header-entity {
      font-size: 15rem;
      color: #F7941D;
      font-family: Libre Baskerville, serif;
      margin-top: 6rem; }
    .testimonial__header-text {
      font-size: 1.8rem;
      color: #F7941D;
      font-family: Libre Baskerville, serif;
      font-weight: 700; }
  .testimonial__text {
    padding: 0 20%;
    font-size: 3rem;
    font-style: italic;
    color: #1B75BC;
    margin-bottom: 5%;
    display: none; }
    .testimonial__text-author {
      text-align: center;
      font-size: 1.5rem; }
  .testimonial__icon {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    align-items: center;
    margin-bottom: 5%; }
    @media only screen and (max-width: 37.5em) {
      .testimonial__icon {
        grid-template-columns: repeat(3, auto); } }

.previous {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  justify-self: end; }
  @media only screen and (max-width: 37.5em) {
    .previous {
      margin-left: 5%; } }

.next {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  justify-self: start; }

.user__iconprev {
  font-size: 10rem;
  font-weight: 300;
  color: #797979;
  font-family: Libre Baskerville, serif; }

.user__text {
  font-size: 1.8rem;
  font-weight: 300;
  color: #797979;
  display: inline-block; }
  .user__text a:link,
  .user__text a:active,
  .user__text a:visited {
    outline: none;
    text-decoration: none;
    color: inherit; }

.user__iconnext {
  font-size: 10rem;
  font-weight: 300;
  color: #1B75BC;
  font-family: Libre Baskerville, serif; }

.userimg {
  width: 11rem; }

.active {
  border: 3px solid #1B75BC;
  border-radius: 50%; }

.prev::before {
  content: '\2190';
  display: inline-block;
  cursor: pointer; }

.nex::after {
  content: '\2192';
  display: inline-block;
  width: 4rem;
  cursor: pointer; }

.avatar__img {
  cursor: pointer; }

.showblogs {
  grid-column: fullstart / fullend;
  grid-row: 4 / 5;
  position: relative;
  margin: 0 auto;
  width: 90%;
  padding: 1% 5%;
  margin-bottom: 2%;
  border: 1px solid #111111;
  overflow-y: scroll; }
  .showblogs__header {
    display: grid;
    grid-template-columns: repeat(4, 25%);
    justify-items: center;
    align-items: center;
    margin-bottom: 2%;
    border: 1px solid #111111;
    width: 100%; }
    .showblogs__header-text {
      font-family: Open Sans;
      font-size: 1.5rem;
      font-weight: 600; }
  .showblogs__infotxt {
    font-family: Open Sans;
    font-size: 1.8rem;
    font-weight: 300; }
  .showblogs__content {
    display: grid;
    grid-template-columns: repeat(4, 25%);
    justify-items: center;
    margin-bottom: 2%; }
    .showblogs__content a,
    .showblogs__content a:active,
    .showblogs__content a:visited {
      color: #F7941D;
      text-decoration: none;
      cursor: pointer; }
  .showblogs__infobtn {
    width: 14rem;
    height: 2.7rem;
    font-family: Open Sans;
    font-size: 1.4rem;
    background-color: #F7941D;
    border: none;
    color: #FFFFFF;
    cursor: pointer;
    outline: none; }

.faq {
  background-color: #d2e8f9;
  grid-column: center-start / center-end;
  grid-row: 7 / 8;
  display: grid;
  grid-template-columns: repeat(2, 1fr); }
  @media only screen and (max-width: 43.75em) {
    .faq {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, max-content);
      padding-top: 0; } }
  .faq__section1 {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    padding: 13% 16%; }
    @media only screen and (max-width: 43.75em) {
      .faq__section1 {
        padding: 2% 2%;
        justify-items: center; } }
    .faq__section1-text {
      color: #1B75BC;
      font-size: 3.6rem;
      font-family: Open Sans; }
    .faq__section1-button {
      margin-top: 8rem;
      border-color: #1B75BC; }
  .faq__section2 {
    display: grid;
    grid-template-rows: repeat(5, min-content);
    align-self: center;
    justify-self: center; }
    @media only screen and (max-width: 43.75em) {
      .faq__section2 {
        padding: 2% 2%;
        justify-items: center; } }
    .faq__section2-text {
      color: #3C3C3C;
      font-size: 1.8rem;
      font-family: inherit; }
      .faq__section2-text:not(:last-child) {
        margin-bottom: 2rem; }
      .faq__section2-text a,
      .faq__section2-text a:link,
      .faq__section2-text a:active,
      .faq__section2-text a:visited {
        outline: none;
        text-decoration: underline;
        color: #3C3C3C;
        cursor: pointer; }

.faqanswer {
  font-family: Open Sans;
  font-size: 1.5rem;
  padding-right: 4rem; }

.faqanswer:not(:last-child) {
  padding-bottom: 2rem; }

#answer1,
#answer2,
#answer3,
#answer4,
#answer5 {
  display: none; }

.info {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 2 / 3;
  padding-bottom: 5%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(700px, 1fr));
  grid-row: auto; }
  @media only screen and (max-width: 87.5em) {
    .info {
      grid-template-columns: repeat(2, auto); } }
  @media only screen and (max-width: 43.75em) {
    .info {
      grid-template-columns: 1fr;
      grid-template-rows: max-content auto;
      padding-top: 0; } }
  @media only screen and (max-width: 43.75em) {
    .info__box {
      grid-row: 1 / 2; } }
  .info__img {
    width: 100%;
    height: auto; }
    @media only screen and (max-width: 43.75em) {
      .info__img {
        grid-row: 1 / 2;
        height: auto;
        width: 60%;
        margin-left: 20%; } }
  .info__text {
    width: 60%;
    text-align: center;
    margin-top: 10%;
    justify-self: center; }
    @media only screen and (max-width: 43.75em) {
      .info__text {
        grid-row: 2 / 3;
        margin-top: 5%;
        text-align: center;
        justify-self: center;
        max-width: 90%;
        width: 80%;
        margin-bottom: 5%; } }
    @media only screen and (max-width: 25em) {
      .info__text {
        max-width: 80%;
        width: 80%; } }

.beliefs {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 3 / 4;
  display: grid;
  grid-template-columns: max-content auto min-content; }
  @media only screen and (max-width: 62.5em) {
    .beliefs {
      grid-template-columns: 1fr;
      padding: 0 5%; } }
  .beliefs__iconleft-1 {
    transform: translate(1%, 65%);
    z-index: 1;
    display: block;
    position: relative; }
    @media only screen and (max-width: 81.25em) {
      .beliefs__iconleft-1 {
        transform: translate(-18%, 65%); } }
    @media only screen and (max-width: 75em) {
      .beliefs__iconleft-1 {
        overflow: hidden;
        transform: translate(-7%, 65%); } }
    @media only screen and (max-width: 62.5em) {
      .beliefs__iconleft-1 {
        display: none; } }
  .beliefs__iconleft-2 {
    z-index: 10;
    display: block;
    position: relative;
    transform: translateY(25%); }
    @media only screen and (max-width: 81.25em) {
      .beliefs__iconleft-2 {
        transform: translate(-10%, 20%); } }
    @media only screen and (max-width: 62.5em) {
      .beliefs__iconleft-2 {
        display: none; } }
  .beliefs__main {
    border: 3px solid #D7D7D7;
    padding-bottom: 10%;
    width: 140%;
    transform: translateX(-10%); }
    @media only screen and (max-width: 81.25em) {
      .beliefs__main {
        width: 170%;
        transform: translateX(-15%); } }
    @media only screen and (max-width: 62.5em) {
      .beliefs__main {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        width: 100%;
        transform: translateX(0%); } }
    .beliefs__main-header {
      transform: translate(-7%, 100%); }
      @media only screen and (max-width: 68.75em) {
        .beliefs__main-header {
          transform: translate(-7%, 180%); } }
      @media only screen and (max-width: 62.5em) {
        .beliefs__main-header {
          transform: translate(0%, 100%); } }
      @media only screen and (max-width: 50em) {
        .beliefs__main-header {
          transform: translate(0%, 100%);
          text-align: center; } }
    .beliefs__main p {
      margin-top: 10%;
      padding-left: 16%;
      padding-right: 16%; }
      @media only screen and (max-width: 81.25em) {
        .beliefs__main p {
          font-size: 1.5rem;
          padding-left: 12%;
          max-width: 93%; } }
      @media only screen and (max-width: 75em) {
        .beliefs__main p {
          font-size: 1.5rem;
          padding-left: 12%;
          max-width: 93%; } }
      @media only screen and (max-width: 68.75em) {
        .beliefs__main p {
          font-size: 1.5rem;
          padding-left: 12%;
          max-width: 93%;
          padding-bottom: 0%;
          padding-top: 10rem; } }
      @media only screen and (max-width: 62.5em) {
        .beliefs__main p {
          font-size: 1.5rem;
          max-width: 93%;
          padding-bottom: 0%;
          padding-top: 5rem; } }
      @media only screen and (max-width: 50em) {
        .beliefs__main p {
          font-size: 1.5rem;
          padding-bottom: 0%;
          padding-top: 5rem;
          justify-self: center;
          padding-left: 5%;
          padding-right: 0;
          text-align: center; } }
  .beliefs__iconright-1 {
    position: relative;
    z-index: 10;
    transform: translate(48%, 10%); }
    @media only screen and (max-width: 81.25em) {
      .beliefs__iconright-1 {
        overflow: hidden; } }
    @media only screen and (max-width: 75em) {
      .beliefs__iconright-1 {
        overflow: hidden;
        transform: translate(48%, 10%); } }
    @media only screen and (max-width: 62.5em) {
      .beliefs__iconright-1 {
        display: none; } }
  .beliefs__iconright-2 {
    transform: translate(0%, -80%); }
    @media only screen and (max-width: 81.25em) {
      .beliefs__iconright-2 {
        overflow: hidden; } }
    @media only screen and (max-width: 68.75em) {
      .beliefs__iconright-2 {
        overflow: hidden; } }
    @media only screen and (max-width: 62.5em) {
      .beliefs__iconright-2 {
        display: none; } }
  .beliefs__iconright-3 {
    transform: translate(163%, -85%);
    overflow: hidden; }
    @media only screen and (max-width: 81.25em) {
      .beliefs__iconright-3 {
        overflow: hidden; } }
    @media only screen and (max-width: 62.5em) {
      .beliefs__iconright-3 {
        display: none; } }

.team {
  padding-top: 5%;
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 4 / 5;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr; }
  .team__top {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    justify-self: center; }
    .team__top h2 {
      margin-left: 36%; }
  .team__mid {
    background-color: #e9f4fc;
    padding: 10% 0;
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center; }
    .team__mid-member1, .team__mid-member2, .team__mid-member3, .team__mid-member4 {
      display: grid;
      justify-items: center; }
    .team__mid img {
      width: 24rem;
      height: 33.7rem; }
  .team__end {
    padding: 8%;
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    display: grid;
    grid-row-gap: 4rem;
    justify-items: center; }
    .team__end p {
      font-family: inherit;
      font-size: 2.1rem;
      font-style: italic; }
      @media only screen and (max-width: 25em) {
        .team__end p {
          font-size: 1.8rem; } }

.blogslide {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 2 / 3;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(700px, 1fr));
  grid-row: auto; }
  @media only screen and (max-width: 87.5em) {
    .blogslide {
      grid-template-columns: repeat(2, 1fr); } }
  @media only screen and (max-width: 43.75em) {
    .blogslide {
      grid-template-columns: 1fr;
      grid-template-rows: 20vh 1fr;
      padding-top: 0; } }
  .blogslide__text {
    padding-left: 15%;
    margin-top: 15%; }
    @media only screen and (max-width: 43.75em) {
      .blogslide__text {
        margin-top: 0;
        padding-left: 5%;
        padding-right: 5%; } }
  @media only screen and (max-width: 43.75em) {
    .blogslide__img {
      justify-self: center; } }
  .blogslide__img img {
    width: 100%;
    height: auto;
    transform: translateY(-10%); }
    @media only screen and (max-width: 43.75em) {
      .blogslide__img img {
        height: 300px;
        width: auto; } }
    @media only screen and (max-width: 31.25em) {
      .blogslide__img img {
        height: 300px;
        width: 300px; } }

.blogdisplay {
  padding-top: 3%;
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 4 / 5;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  grid-row-gap: 6rem;
  padding-left: 1%; }
  @media only screen and (max-width: 43.75em) {
    .blogdisplay {
      grid-template-columns: repeat(2, 1fr); } }
  @media only screen and (max-width: 31.25em) {
    .blogdisplay {
      grid-template-columns: auto; } }
  .blogdisplay__imgbox {
    background-color: #FDF5E8; }
  .blogdisplay img {
    width: 34.4rem;
    height: 24.3rem;
    transform: translate(-4%, 6%); }
  .blogdisplay p {
    padding-top: 6%;
    font-family: inherit;
    font-size: 1.4rem;
    color: #F7941D;
    font-weight: 300;
    margin-left: -4%; }
  .blogdisplay__header {
    margin-left: -4%;
    margin-bottom: 1.5rem; }
  .blogdisplay a:link,
  .blogdisplay a:active,
  .blogdisplay a:visited {
    margin-left: -4%;
    font-family: Open Sans;
    font-size: 1.4rem;
    font-weight: 400;
    color: #1B75BC; }
  .blogdisplay h4 {
    max-width: 340px; }
    @media only screen and (max-width: 87.5em) {
      .blogdisplay h4 {
        max-width: 400px; } }
    @media only screen and (max-width: 68.75em) {
      .blogdisplay h4 {
        max-width: 300px;
        font-size: 1.5rem; } }
    @media only screen and (max-width: 43.75em) {
      .blogdisplay h4 {
        max-width: 200px; } }

.moreinfo {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 5 / 6;
  display: grid;
  justify-items: center;
  grid-row-gap: 10rem;
  padding: 8%; }

.dynamicmain {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 2 / 3;
  grid-column-gap: 2rem;
  padding-left: 4rem;
  padding-right: 4rem;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  padding-bottom: 10rem; }
  @media only screen and (max-width: 37.5em) {
    .dynamicmain {
      padding-left: 1rem;
      padding-right: 1rem;
      grid-column-gap: 0;
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
      padding-bottom: 10rem;
      grid-row-gap: 2rem; } }
  @media only screen and (max-width: 31.25em) {
    .dynamicmain {
      padding-left: 1rem;
      padding-right: 1rem;
      grid-column-gap: 0;
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
      padding-bottom: 10rem;
      grid-row-gap: 2rem; } }
  @media only screen and (max-width: 25em) {
    .dynamicmain {
      padding-left: 1rem;
      padding-right: 1rem;
      grid-column-gap: 0;
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
      padding-bottom: 10rem;
      grid-row-gap: 2rem; } }
  .dynamicmain__product {
    border: 1px solid #7EABCF;
    border-radius: 5px;
    display: grid;
    grid-template-columns: repeat(2, 1fr); }
    .dynamicmain__product-left {
      display: grid;
      grid-template-rows: repeat(6, min-content);
      justify-items: center;
      padding-right: 30%;
      padding-top: 8rem;
      padding-bottom: 8rem; }
    .dynamicmain__product-right {
      display: grid;
      grid-template-rows: repeat(6, min-content);
      justify-items: center;
      padding-top: 8rem;
      padding-bottom: 8rem; }
      @media only screen and (max-width: 25em) {
        .dynamicmain__product-right {
          grid-template-rows: repeat(6, auto); } }
    .dynamicmain__product-price {
      margin-bottom: 4rem; }
    .dynamicmain__product-review {
      margin-bottom: 12rem; }
    .dynamicmain__product-features {
      margin-bottom: 17rem; }
    .dynamicmain__product-button {
      justify-self: start; }

.progressivemain {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 2 / 3;
  grid-column-gap: 2rem;
  padding-left: 4rem;
  padding-right: 4rem;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  padding-bottom: 10rem; }
  @media only screen and (max-width: 37.5em) {
    .progressivemain {
      padding-left: 1rem;
      padding-right: 1rem;
      grid-column-gap: 0;
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
      padding-bottom: 10rem;
      grid-row-gap: 2rem; } }
  @media only screen and (max-width: 31.25em) {
    .progressivemain {
      padding-left: 1rem;
      padding-right: 1rem;
      grid-column-gap: 0;
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
      padding-bottom: 10rem;
      grid-row-gap: 2rem; } }
  @media only screen and (max-width: 25em) {
    .progressivemain {
      padding-left: 1rem;
      padding-right: 1rem;
      grid-column-gap: 0;
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
      padding-bottom: 10rem;
      grid-row-gap: 2rem; } }
  .progressivemain__product {
    border: 1px solid #F7941D;
    border-radius: 5px;
    display: grid;
    grid-template-columns: repeat(2, 1fr); }
    .progressivemain__product-left {
      display: grid;
      grid-template-rows: repeat(6, min-content);
      justify-items: center;
      padding-right: 30%;
      padding-top: 8rem;
      padding-bottom: 8rem; }
    .progressivemain__product-right {
      display: grid;
      grid-template-rows: repeat(6, min-content);
      justify-items: center;
      padding-top: 8rem;
      padding-bottom: 8rem; }
    .progressivemain__product-price {
      margin-bottom: 4rem; }
    .progressivemain__product-review {
      margin-bottom: 12rem; }
    .progressivemain__product-features {
      margin-bottom: 8rem; }

.enterprisemain {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 2 / 3;
  grid-column-gap: 2rem;
  padding-left: 4rem;
  padding-right: 4rem;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  padding-bottom: 10rem; }
  @media only screen and (max-width: 37.5em) {
    .enterprisemain {
      padding-left: 1rem;
      padding-right: 1rem;
      grid-column-gap: 0;
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
      padding-bottom: 10rem;
      grid-row-gap: 2rem; } }
  @media only screen and (max-width: 31.25em) {
    .enterprisemain {
      padding-left: 1rem;
      padding-right: 1rem;
      grid-column-gap: 0;
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
      padding-bottom: 10rem;
      grid-row-gap: 2rem; } }
  @media only screen and (max-width: 25em) {
    .enterprisemain {
      padding-left: 1rem;
      padding-right: 1rem;
      grid-column-gap: 0;
      display: grid;
      grid-template-columns: 1fr;
      align-items: center;
      justify-items: center;
      padding-bottom: 10rem;
      grid-row-gap: 2rem; } }
  .enterprisemain__product {
    border: 1px solid #FF673E;
    border-radius: 5px;
    display: grid;
    grid-template-columns: repeat(2, 1fr); }
    .enterprisemain__product-left {
      display: grid;
      grid-template-rows: repeat(6, min-content);
      justify-items: center;
      padding-right: 30%;
      padding-top: 8rem;
      padding-bottom: 8rem; }
    .enterprisemain__product-right {
      display: grid;
      grid-template-rows: repeat(6, min-content);
      justify-items: center;
      padding-top: 8rem;
      padding-bottom: 8rem; }
    .enterprisemain__product-price {
      margin-bottom: 4rem; }
    .enterprisemain__product-review {
      margin-bottom: 12rem; }
    .enterprisemain__product-features {
      margin-bottom: 8rem; }

.blogmain {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 2 / 3;
  display: grid;
  grid-template-rows: repeat(6, auto);
  justify-items: center; }
  .blogmain__header {
    grid-row: 1 / 2;
    max-width: 90%;
    padding: 0 10%;
    margin-bottom: 6rem; }
  .blogmain__author {
    grid-row: 2 / 3; }
  .blogmain__date {
    grid-row: 3 / 4;
    font-size: 1.6rem;
    font-weight: 400;
    font-family: Open Sans;
    color: #8A8A8A;
    margin-bottom: 4rem; }
  .blogmain__image {
    grid-row: 4 / 5;
    width: 70%;
    height: auto;
    margin-bottom: 5rem; }
  .blogmain__text {
    grid-row: 5 / 6;
    padding-left: 15%;
    padding-right: 15%; }
  .blogmain__footer {
    grid-row: 6 / 7;
    justify-self: start;
    padding-left: 15%;
    margin-bottom: 8rem;
    display: grid;
    grid-template-columns: 1fr; }
    .blogmain__footer-text {
      font-family: Open Sans;
      font-size: 1.8rem;
      font-weight: 400;
      margin-bottom: 4rem;
      margin-top: 3rem; }
    .blogmain__footer-categories {
      color: #6D6E71;
      margin-bottom: 4rem; }
    .blogmain__footer-items {
      display: grid;
      grid-template-columns: repeat(3, max-content);
      grid-column-gap: 2rem; }

.related {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 3 / 4;
  display: grid;
  grid-template-rows: auto max-content;
  grid-row-gap: 5rem; }
  .related__posts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    grid-row-gap: 6rem;
    padding-left: 1%;
    margin-bottom: 10rem; }
    @media only screen and (max-width: 43.75em) {
      .related__posts {
        grid-template-columns: auto; } }
  .related__heading {
    justify-self: center;
    font-size: 1.8rem;
    font-family: Open Sans;
    color: #1B75BC; }
  .related__imgbox {
    background-color: #FDF5E8; }
  .related img {
    width: 34.4rem;
    height: 24.3rem;
    transform: translate(-4%, 6%); }
  .related p {
    padding-top: 6%;
    font-family: inherit;
    font-size: 1.4rem;
    color: #F7941D;
    font-weight: 300;
    margin-left: -4%; }
  .related__header {
    margin-left: -4%;
    max-width: 300px; }
  .related a:link,
  .related a:active,
  .related a:visited {
    margin-left: -4%;
    font-family: Open Sans;
    font-size: 1.4rem;
    font-weight: 400;
    color: #1B75BC; }

.systemblogs {
  grid-column: fullstart / fullend;
  grid-row: 3 / 4;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-items: center;
  position: relative;
  margin: 0 auto;
  padding: 1% 0; }
  .systemblogs__buttons a,
  .systemblogs__buttons a:active,
  .systemblogs__buttons a:visited {
    text-decoration: none;
    cursor: pointer; }
  .systemblogs__buttons:not(:last-child) {
    margin-right: 10%; }
  .systemblogs__btn {
    width: 27rem;
    height: 4.7rem;
    font-family: Open Sans;
    font-size: 1.4rem;
    background-color: #F7941D;
    border: none;
    color: #FFFFFF;
    cursor: pointer; }

/*

.schedule {
    background-color: $color-white;
    padding: 8rem;
    grid-column: full-start / -1;
    grid-row: 2 / 3;

    &__header {
        padding-left:5%;
        margin-bottom: 6rem;
    }
}

*/
.contact {
  background-color: #FFFFFF;
  padding: 4rem;
  grid-column: center-start / center-end;
  grid-row: 2 / 3;
  display: grid;
  grid-template-columns: 40% 60%;
  grid-column-gap: 3rem; }
  @media only screen and (max-width: 62.5em) {
    .contact {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, max-content);
      padding-top: 0;
      justify-self: center; } }
  @media only screen and (max-width: 43.75em) {
    .contact {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, max-content);
      padding-top: 0;
      justify-self: center; } }
  .contact__left {
    padding-left: 13%;
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-row-gap: 4rem; }
    .contact__left-text {
      max-width: 70%;
      word-wrap: break-word; }
    .contact__left-img {
      width: 100%;
      transform: translate(-10%, 0%); }
  .contact__right {
    align-self: center;
    display: grid;
    justify-items: center;
    grid-column-gap: 4rem; }
    .contact__right table, .contact__right td {
      width: 100%;
      padding: 20px; }
    @media only screen and (max-width: 43.75em) {
      .contact__right {
        margin-top: 5rem; } }
  .contact__message {
    font-size: 1.6rem; }

.form {
  font-size: 1.4rem;
  font-family: inherit;
  font-weight: 600; }
  .form__label {
    font-size: 1.6rem; }
  .form__input {
    width: 331px;
    height: 48px;
    background-color: #F8F8F8;
    border-radius: 5px;
    border: solid 1px #B4B4B4;
    padding: 1.5rem 2rem;
    font-size: 1.6rem; }
    @media only screen and (max-width: 81.25em) {
      .form__input {
        width: 30rem; } }
    @media only screen and (max-width: 75em) {
      .form__input {
        width: 25rem; } }
    @media only screen and (max-width: 68.75em) {
      .form__input {
        width: 25rem; } }
    @media only screen and (max-width: 62.5em) {
      .form__input {
        width: 331px; } }
    .form__input:focus {
      outline: none; }
    .form__input:focus:invalid {
      border: solid 1px #F7941D; }
    .form__input:focus:valid {
      border: solid 1px green; }
  .form__checkbox {
    width: 2rem;
    height: 2rem;
    margin-right: 2rem;
    vertical-align: bottom; }
    @media only screen and (max-width: 81.25em) {
      .form__checkbox {
        width: 2rem;
        height: 2rem;
        margin-right: 2rem; } }
  .form__textarea {
    width: 686px;
    height: 182px;
    border: solid 1px #B4B4B4;
    background-color: #F8F8F8;
    border-radius: 5px;
    resize: none;
    padding: 1.5rem 2rem;
    font-size: 1.5rem;
    margin-bottom: 3rem;
    font-family: Open Sans; }
    @media only screen and (max-width: 81.25em) {
      .form__textarea {
        width: 60rem; } }
    @media only screen and (max-width: 75em) {
      .form__textarea {
        width: 55rem; } }
    @media only screen and (max-width: 68.75em) {
      .form__textarea {
        width: 55rem; } }
    @media only screen and (max-width: 62.5em) {
      .form__textarea {
        width: 686px; } }
    @media only screen and (max-width: 43.75em) {
      .form__textarea {
        width: 60rem; } }
    @media only screen and (max-width: 31.25em) {
      .form__textarea {
        width: 40rem; } }
    @media only screen and (max-width: 25em) {
      .form__textarea {
        width: 30rem; } }
    .form__textarea:focus {
      outline: none; }
    .form__textarea:focus:invalid {
      border: solid 1px #F7941D; }
    .form__textarea:focus:valid {
      border: solid 1px green; }
  .form__button {
    text-align: center; }

.firstrow {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 4rem;
  padding-bottom: 4rem; }
  @media only screen and (max-width: 43.75em) {
    .firstrow {
      grid-template-columns: 1fr;
      grid-row-gap: 4rem;
      justify-self: center; } }

.secondrow {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 4rem;
  padding-bottom: 4rem; }
  @media only screen and (max-width: 43.75em) {
    .secondrow {
      grid-template-columns: 1fr;
      grid-row-gap: 4rem;
      justify-self: center; } }

.thirdrow {
  display: grid;
  grid-template-columns: 1fr;
  justify-self: start;
  font-weight: 400;
  font-size: 1.4rem;
  padding-bottom: 4rem;
  align-self: center; }
  .thirdrow label {
    cursor: pointer; }
  @media only screen and (max-width: 68.75em) {
    .thirdrow {
      padding-left: 0; } }
  @media only screen and (max-width: 62.5em) {
    .thirdrow {
      padding-left: 15rem; } }
  @media only screen and (max-width: 43.75em) {
    .thirdrow {
      justify-self: center;
      padding-left: 0; } }

.fourthrow {
  display: grid;
  grid-template-columns: 1fr;
  justify-self: start;
  font-size: 1.4rem;
  font-weight: 600; }
  @media only screen and (max-width: 68.75em) {
    .fourthrow {
      padding-left: 0; } }
  @media only screen and (max-width: 62.5em) {
    .fourthrow {
      padding-left: 0; } }
  @media only screen and (max-width: 56.25em) {
    .fourthrow {
      padding-left: 0; } }
  @media only screen and (max-width: 50em) {
    .fourthrow {
      padding-left: 0; } }
  @media only screen and (max-width: 43.75em) {
    .fourthrow {
      justify-self: center;
      padding-left: 0; } }
  .fourthrow p {
    margin-bottom: 1rem; }

.navigation {
  display: none; }
  @media only screen and (max-width: 43.75em) {
    .navigation {
      display: block; } }
  .navigation__checkbox {
    display: none; }
  .navigation__button {
    background-color: #fbd19d;
    height: 7rem;
    width: 7rem;
    position: fixed;
    top: 6rem;
    right: 8%;
    border-radius: 50%;
    z-index: 2000;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 1rem 3rem rgba(17, 17, 17, 0.1); }
  .navigation__background {
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    position: fixed;
    top: 6.5rem;
    right: 8%;
    z-index: 1000;
    background-image: radial-gradient(#F7941D, #fbd19d);
    transition: transform .8s; }
  .navigation__nav {
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1500;
    width: 0;
    transition: all .8s;
    visibility: hidden; }
  .navigation__list {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    list-style: none;
    text-align: center;
    z-index: 1800; }
  .navigation__item {
    margin: 1rem; }
  .navigation__link {
    font-size: 3rem;
    font-weight: 300;
    color: #FFFFFF;
    text-decoration: none; }
  .navigation__checkbox:checked ~ .navigation__background {
    transform: scale(80); }
  .navigation__checkbox:checked ~ .navigation__nav {
    visibility: visible; }
  .navigation__icon {
    position: relative;
    margin-top: 3.5rem; }
    .navigation__icon, .navigation__icon::before, .navigation__icon::after {
      width: 3rem;
      height: 2px;
      background-color: #111111;
      display: inline-block; }
    .navigation__icon::before, .navigation__icon::after {
      content: "";
      position: absolute;
      left: 0; }
    .navigation__icon::before {
      top: -.8em; }
    .navigation__icon::after {
      top: .8rem; }
  .navigation__checkbox:checked + .navigation__button .navigation__icon {
    background-color: transparent; }
  .navigation__checkbox:checked + .navigation__button .navigation__icon::before {
    top: 0;
    transform: rotate(45deg); }
  .navigation__checkbox:checked + .navigation__button .navigation__icon::after {
    top: 0;
    transform: rotate(-45deg); }

.partner {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 2 / 3;
  padding-bottom: 10%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, max-content)); }
  @media only screen and (max-width: 87.5em) {
    .partner {
      grid-template-columns: repeat(2, 1fr); } }
  @media only screen and (max-width: 43.75em) {
    .partner {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, max-content);
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0;
      justify-items: center; } }
  .partner__right {
    transform: translate(-5%, 0); }
    @media only screen and (max-width: 43.75em) {
      .partner__right {
        transform: translate(0, 0); } }
    @media only screen and (max-width: 81.25em) {
      .partner__right-image {
        width: 95%; } }
    @media only screen and (max-width: 43.75em) {
      .partner__right-image {
        width: 80%;
        height: 80%;
        padding-left: 15%;
        padding-bottom: 8%; } }
  .partner__left-header {
    transform: translate(10%, 10%);
    margin-bottom: 5%; }
    @media only screen and (max-width: 87.5em) {
      .partner__left-header {
        width: 105%; } }
    @media only screen and (max-width: 43.75em) {
      .partner__left-header {
        width: 100%;
        transform: translate(0, 0); } }
    @media only screen and (max-width: 37.5em) {
      .partner__left-header {
        width: 80%;
        padding-left: 15%;
        text-align: center; } }
    .partner__left-header a:link,
    .partner__left-header a:active,
    .partner__left-header a:visited {
      outline: none; }
  .partner__left-btn {
    margin-top: 10%;
    margin-left: 15%; }
    @media only screen and (max-width: 43.75em) {
      .partner__left-btn {
        margin-left: 35%;
        margin-bottom: 5%; } }
    @media only screen and (max-width: 31.25em) {
      .partner__left-btn {
        margin-left: 30%;
        margin-bottom: 5%; } }
    @media only screen and (max-width: 25em) {
      .partner__left-btn {
        margin-left: 25%;
        margin-bottom: 5%; } }

.core {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 3 / 4;
  padding-bottom: 15%;
  display: grid;
  grid-template-columns: repeat(2, 1fr); }
  @media only screen and (max-width: 37.5em) {
    .core {
      grid-template-columns: 1fr; } }
  .core__right {
    border-left: solid 2px #208cdf;
    padding-left: 15%;
    padding-right: 5%; }
    @media only screen and (max-width: 37.5em) {
      .core__right {
        border-left: none;
        padding-left: 7%;
        padding-right: 5%;
        padding-top: 5%;
        text-align: center; } }
    .core__right-body {
      margin-bottom: 4rem; }
  .core__left {
    justify-self: center; }

.ourservices {
  background-color: #d2e8f9;
  grid-column: center-start / center-end;
  grid-row: 4 / 5;
  padding-top: 6rem;
  padding-left: 6rem;
  padding-right: 6rem;
  padding-bottom: 10rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr); }
  @media only screen and (max-width: 25em) {
    .ourservices {
      padding-bottom: 15rem; } }
  @media only screen and (max-width: 37.5em) {
    .ourservices {
      grid-template-columns: 1fr; } }
  .ourservices__right {
    background-color: #d2e8f9;
    transform: translate(0, -9.3%);
    display: grid;
    grid-template-rows: repeat(2, auto); }
    @media only screen and (max-width: 25em) {
      .ourservices__right {
        transform: translate(0, -7%); } }
    .ourservices__right-header {
      font-family: Open Sans;
      font-size: 3rem;
      line-height: 1.4;
      font-weight: 600; }
    .ourservices__right-body:not(:last-child) {
      margin-bottom: 2rem; }
    .ourservices__right-top {
      background-color: #bcddf5;
      padding: 8rem 6rem;
      transform: translate(0, 10%); }
    .ourservices__right-bottom {
      background-color: #bcddf5;
      padding: 8rem 6rem;
      transform: translate(0, 20%); }
  @media only screen and (max-width: 37.5em) {
    .ourservices__left {
      transform: translate(0, -200%);
      justify-self: center; } }

.systemheader {
  grid-column: fullstart / fullend;
  grid-row: 1 / 2;
  border-bottom: 1px solid #F7941D; }
  .systemheader__logo {
    display: block;
    position: relative;
    margin: 0 auto;
    width: 25rem; }

.systemlogin {
  grid-column: fullstart / fullend;
  grid-row: 2 / 3;
  padding: 10% 0;
  display: grid;
  grid-template-rows: repeat(5, auto);
  justify-items: center; }
  .systemlogin__info, .systemlogin__passinfo {
    display: grid;
    grid-template-columns: repeat(2, max-content); }
  .systemlogin__label {
    font-family: Open Sans;
    font-size: 2.3rem;
    font-weight: 300;
    margin-right: 10rem; }
  .systemlogin__input {
    width: 30rem;
    height: 4.7rem;
    border-radius: 5px;
    outline: none;
    border: 1px solid #FF673E;
    font-family: Open Sans;
    font-size: 2rem;
    font-weight: 300;
    padding: 2%;
    margin-bottom: 10%; }
  .systemlogin__text {
    font-family: Open Sans;
    font-size: 1.8rem;
    font-weight: 300;
    margin-bottom: 5%; }
  .systemlogin__anchor {
    margin-bottom: 1%; }
    .systemlogin__anchor a,
    .systemlogin__anchor a:active,
    .systemlogin__anchor a:visited {
      font-family: Open Sans;
      font-size: 1.8rem;
      font-weight: 300;
      margin-bottom: 5%;
      text-decoration: none;
      cursor: pointer; }
  .systemlogin__btn {
    width: 27rem;
    height: 4.7rem;
    border: none;
    background-color: #F7941D;
    cursor: pointer;
    outline: none;
    color: #FFFFFF;
    margin-bottom: 5%; }

.systemfooter {
  grid-column: fullstart / fullend;
  font-family: Open Sans;
  font-size: 1.8rem;
  font-weight: 300;
  position: relative;
  margin: 0 auto;
  padding-top: 5%; }

.consult {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 5 / 6;
  padding: 6rem 0;
  display: grid;
  grid-template-rows: repeat(2, auto);
  justify-items: center;
  grid-row-gap: 4rem; }
  .consult__text {
    font-style: italic;
    font-weight: 700 !important; }

.mailinglist {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 6 / 7;
  display: grid;
  grid-template-rows: repeat(3, auto);
  justify-items: center;
  grid-row-gap: 3rem;
  padding: 6rem 0; }
  .mailinglist__top {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-column-gap: 3rem; }
  .mailinglist__icon {
    width: 3.7rem;
    height: 3.7rem; }
  .mailinglist__header {
    font-family: Open Sans;
    font-weight: 700;
    font-size: 2.4rem;
    color: #111111; }
  .mailinglist__body {
    font-family: Open Sans;
    font-weight: 300;
    font-size: 1.4rem;
    color: #111111;
    padding: 0 27%;
    text-align: center; }
  .mailinglist__textbox {
    width: 60rem;
    height: 4rem;
    border: none;
    border-bottom: solid 1px #C1C1C1;
    outline: none;
    font-weight: 300;
    margin-right: 2rem;
    padding-left: 30%; }
    @media only screen and (max-width: 43.75em) {
      .mailinglist__textbox {
        margin-bottom: 4rem; } }
    @media only screen and (max-width: 31.25em) {
      .mailinglist__textbox {
        width: 40rem;
        padding-left: 0%; } }
    @media only screen and (max-width: 25em) {
      .mailinglist__textbox {
        width: 300px;
        margin-left: 2rem;
        margin-bottom: 2rem; } }
    .mailinglist__textbox::-webkit-input-placeholder {
      color: #111111;
      font-family: Open Sans;
      font-size: 1.4rem;
      font-weight: 300;
      color: #C1C1C1; }
    .mailinglist__textbox:focus {
      color: #111111;
      font-family: Open Sans;
      font-size: 1.4rem;
      font-weight: 300;
      color: #111111;
      padding-left: 30%; }
      @media only screen and (max-width: 31.25em) {
        .mailinglist__textbox:focus {
          padding-left: 0; } }
    .mailinglist__textbox:focus:invalid {
      border-bottom: solid 1px #F7941D; }
    .mailinglist__textbox:focus:valid {
      border-bottom: solid 1px green; }
  .mailinglist__button {
    width: 15.8rem;
    height: 3.5rem;
    font-family: Open Sans;
    font-size: 1.4rem;
    color: #FFFFFF;
    background-color: #1B75BC;
    outline: none;
    cursor: pointer;
    border: none;
    border-radius: 50px; }
    @media only screen and (max-width: 43.75em) {
      .mailinglist__button {
        justify-self: center; } }
    @media only screen and (max-width: 25em) {
      .mailinglist__button {
        margin-left: 0; } }
  .mailinglist__bottom {
    display: grid;
    grid-template-columns: repeat(2, auto);
    transform: translate(13%, 0); }
    @media only screen and (max-width: 50em) {
      .mailinglist__bottom {
        transform: translate(0, 0); } }
    @media only screen and (max-width: 43.75em) {
      .mailinglist__bottom {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: repeat(2, auto);
        transform: translate(0, 0); } }

.ebookheader {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 6 / 7;
  padding: 2% 5%; }
  .ebookheader div {
    background-color: #FDF5E8;
    display: inline-block; }
  .ebookheader__heading {
    font-family: Open Sans;
    font-weight: 700;
    font-size: 2.4rem;
    color: #1B75BC;
    transform: translate(-10%, 6%); }

.blogsheader {
  grid-column: center-start / center-end;
  grid-row: 3 / 4;
  background-color: #FFFFFF;
  padding: 2% 5%; }
  .blogsheader div {
    background-color: #FDF5E8;
    display: inline-block; }
  .blogsheader__heading {
    font-family: Open Sans;
    font-weight: 700;
    font-size: 2.4rem;
    color: #1B75BC;
    transform: translate(-10%, 6%); }

.ebookdisplay {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 7 / 8;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  grid-row-gap: 6rem;
  padding-left: 1%; }
  @media only screen and (max-width: 43.75em) {
    .ebookdisplay {
      grid-template-columns: repeat(2, 1fr); } }
  @media only screen and (max-width: 31.25em) {
    .ebookdisplay {
      grid-template-columns: auto; } }
  .ebookdisplay__img {
    width: 29.2rem;
    height: 41.0rem; }
  .ebookdisplay__date {
    padding-top: 6%;
    font-family: inherit;
    font-size: 1.4rem;
    color: #F7941D;
    font-weight: 300;
    margin-left: -4%;
    padding-bottom: 1rem; }
  .ebookdisplay__title {
    max-width: 340px;
    margin-left: -4%;
    padding-bottom: 1rem; }
    @media only screen and (max-width: 87.5em) {
      .ebookdisplay__title {
        max-width: 400px; } }
    @media only screen and (max-width: 68.75em) {
      .ebookdisplay__title {
        max-width: 300px;
        font-size: 1.5rem; } }
    @media only screen and (max-width: 43.75em) {
      .ebookdisplay__title {
        max-width: 200px; } }
  .ebookdisplay a:link,
  .ebookdisplay a:active,
  .ebookdisplay a:visited {
    margin-left: -4%;
    font-family: Open Sans;
    font-size: 1.4rem;
    font-weight: 400;
    color: #1B75BC; }

.moreinfo2 {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 8 / 9;
  display: grid;
  justify-items: center;
  grid-row-gap: 10rem;
  padding: 8%; }
  @media only screen and (max-width: 43.75em) {
    .moreinfo2 img {
      width: 50px; } }

.addblogs {
  grid-column: fullstart / fullend;
  grid-row: 4 / 5;
  position: relative;
  margin: 0 auto;
  width: 90%;
  padding: 2% 5%;
  margin-bottom: 2%;
  border: 1px solid #6C7F8E;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 100%;
  justify-items: center; }
  .addblogs__content {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    width: 80%; }
  .addblogs__label {
    font-family: Open Sans;
    font-size: 2.4rem;
    font-weight: 300; }
  .addblogs__input {
    width: 57rem;
    height: 4.7rem;
    font-family: Open Sans;
    font-size: 1.8rem;
    font-weight: 300;
    padding: 2%;
    margin-bottom: 2%;
    outline: none; }
  .addblogs__excerpt {
    width: 57rem;
    height: 6.7rem;
    font-family: Open Sans;
    font-size: 1.8rem;
    font-weight: 300;
    padding: 2%;
    margin-bottom: 2%;
    resize: none;
    outline: none; }
  .addblogs__main {
    width: 100rem;
    height: 50rem;
    font-family: Open Sans;
    font-size: 1.8rem;
    font-weight: 300;
    padding: 2%;
    margin-bottom: 2%;
    resize: none;
    outline: none; }
  .addblogs__btn {
    width: 14rem;
    height: 2.7rem;
    font-family: Open Sans;
    font-size: 1.4rem;
    background-color: #F7941D;
    border: none;
    color: #FFFFFF;
    cursor: pointer;
    outline: none; }

.blogslideall {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 2 / 3;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(700px, 1fr));
  grid-row: auto; }
  @media only screen and (max-width: 87.5em) {
    .blogslideall {
      grid-template-columns: repeat(2, 1fr); } }
  @media only screen and (max-width: 43.75em) {
    .blogslideall {
      grid-template-columns: 1fr;
      grid-template-rows: 20vh 1fr;
      padding-top: 0; } }
  .blogslideall__text {
    padding-left: 15%;
    margin-top: 15%; }
    @media only screen and (max-width: 43.75em) {
      .blogslideall__text {
        margin-top: 0;
        padding-left: 5%;
        padding-right: 5%; } }
  @media only screen and (max-width: 43.75em) {
    .blogslideall__img {
      justify-self: center; } }
  .blogslideall__img img {
    width: 100%;
    height: auto;
    transform: translateY(-10%); }
    @media only screen and (max-width: 43.75em) {
      .blogslideall__img img {
        height: 300px;
        width: auto; } }
    @media only screen and (max-width: 31.25em) {
      .blogslideall__img img {
        height: 300px;
        width: 300px; } }

.blogdisplayall {
  padding-top: 3%;
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 3 / 4;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  grid-row-gap: 6rem;
  padding-left: 1%; }
  @media only screen and (max-width: 43.75em) {
    .blogdisplayall {
      grid-template-columns: repeat(2, 1fr); } }
  @media only screen and (max-width: 31.25em) {
    .blogdisplayall {
      grid-template-columns: auto; } }
  .blogdisplayall__imgbox {
    background-color: #FDF5E8; }
  .blogdisplayall img {
    width: 34.4rem;
    height: 24.3rem;
    transform: translate(-4%, 6%); }
  .blogdisplayall p {
    padding-top: 6%;
    font-family: inherit;
    font-size: 1.4rem;
    color: #F7941D;
    font-weight: 300;
    margin-left: -4%; }
  .blogdisplayall__header {
    margin-left: -4%;
    margin-bottom: 1.5rem; }
  .blogdisplayall a:link,
  .blogdisplayall a:active,
  .blogdisplayall a:visited {
    margin-left: -4%;
    font-family: Open Sans;
    font-size: 1.4rem;
    font-weight: 400;
    color: #1B75BC; }
  .blogdisplayall h4 {
    max-width: 340px; }
    @media only screen and (max-width: 87.5em) {
      .blogdisplayall h4 {
        max-width: 400px; } }
    @media only screen and (max-width: 68.75em) {
      .blogdisplayall h4 {
        max-width: 300px;
        font-size: 1.5rem; } }
    @media only screen and (max-width: 43.75em) {
      .blogdisplayall h4 {
        max-width: 200px; } }

.moreinfoall {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 4 / 5;
  display: grid;
  justify-items: center;
  grid-row-gap: 10rem;
  padding: 8%; }
  @media only screen and (max-width: 43.75em) {
    .moreinfoall img {
      width: 50px; } }

.systemnav {
  grid-column: fullstart / fullend;
  grid-row: 2 / 3;
  margin: 0 auto;
  position: relative;
  padding: 2% 0;
  display: grid;
  width: 100%;
  grid-template-columns: 100%;
  justify-items: center;
  border-bottom: 1px solid #F7941D; }
  .systemnav__nav ul {
    list-style-type: none; }
  .systemnav__nav li {
    display: inline-block;
    font-family: Open Sans;
    font-size: 1.8rem;
    margin-right: 5rem; }
  .systemnav__nav a,
  .systemnav__nav a:active,
  .systemnav__nav a:visited {
    color: #F7941D;
    text-decoration: none;
    cursor: pointer; }

.allblogcontainer {
  display: grid;
  grid-template-rows: max-content auto max-content repeat(2, auto);
  grid-template-columns: [full-start] 1fr [center-start] repeat(12, minmax(min-content, 14rem)) [center-end] 1fr [full-end]; }

.maincontainer {
  display: grid;
  grid-template-rows: max-content repeat(6, auto);
  grid-template-columns: [full-start] 1fr [center-start] repeat(12, minmax(min-content, 14rem)) [center-end] 1fr [full-end]; }
  @media only screen and (max-width: 37.5em) {
    .maincontainer {
      grid-template-rows: max-content repeat(4, auto) auto; } }
  @media only screen and (max-width: 50em) {
    .maincontainer {
      grid-template-rows: max-content repeat(4, auto) auto; } }

.resumecontainer {
  display: grid;
  grid-template-rows: max-content repeat(6, auto) auto;
  grid-template-columns: [full-start] 1fr [center-start] repeat(12, minmax(min-content, 14rem)) [center-end] 1fr [full-end]; }

.aboutcontainer {
  display: grid;
  grid-template-rows: max-content repeat(3, auto) auto;
  grid-template-columns: [full-start] 1fr [center-start] repeat(12, minmax(min-content, 14rem)) [center-end] 1fr [full-end]; }

.dynamiccontainer {
  display: grid;
  grid-template-rows: max-content auto auto;
  grid-template-columns: [full-start] 1fr [center-start] repeat(12, minmax(min-content, 14rem)) [center-end] 1fr [full-end]; }
  @media only screen and (max-width: 50em) {
    .dynamiccontainer {
      grid-template-rows: 20vh 60vh 20vh; } }
  @media only screen and (max-width: 43.75em) {
    .dynamiccontainer {
      grid-template-rows: max-content auto auto; } }

.blogcontainer {
  display: grid;
  grid-template-rows: max-content auto auto max-content repeat(5, auto);
  grid-template-columns: [full-start] 1fr [center-start] repeat(12, minmax(min-content, 14rem)) [center-end] 1fr [full-end]; }

.progressivecontainer {
  display: grid;
  grid-template-rows: max-content auto auto;
  grid-template-columns: [full-start] 1fr [center-start] repeat(12, minmax(min-content, 14rem)) [center-end] 1fr [full-end]; }
  @media only screen and (max-width: 50em) {
    .progressivecontainer {
      grid-template-rows: 20vh 60vh 20vh; } }
  @media only screen and (max-width: 43.75em) {
    .progressivecontainer {
      grid-template-rows: max-content auto auto; } }

.enterprisecontainer {
  display: grid;
  grid-template-rows: max-content auto auto;
  grid-template-columns: [full-start] 1fr [center-start] repeat(12, minmax(min-content, 14rem)) [center-end] 1fr [full-end]; }
  @media only screen and (max-width: 50em) {
    .enterprisecontainer {
      grid-template-rows: 20vh 60vh 20vh; } }
  @media only screen and (max-width: 43.75em) {
    .enterprisecontainer {
      grid-template-rows: max-content auto auto; } }

.mainblogcontainer {
  display: grid;
  grid-template-rows: max-content auto max-content auto;
  grid-template-columns: [full-start] 1fr [center-start] repeat(12, minmax(min-content, 14rem)) [center-end] 1fr [full-end]; }

.contactcontainer {
  display: grid;
  grid-template-rows: max-content repeat(2, auto);
  grid-template-columns: [full-start] 1fr [center-start] repeat(12, minmax(min-content, 14rem)) [center-end] 1fr [full-end]; }

.employercontainer {
  display: grid;
  grid-template-rows: max-content repeat(4, auto) auto;
  grid-template-columns: [full-start] 1fr [center-start] repeat(12, minmax(min-content, 14rem)) [center-end] 1fr [full-end]; }

.systemcontainer {
  background-color: #FFFFFF;
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: [fullstart] auto [centerstart] repeat(10, minmax(min-content, 14.4rem)) [centerend] auto [fullend]; }

.admincontainer {
  background-color: #FFFFFF;
  display: grid;
  grid-template-rows: repeat(5, auto);
  grid-template-columns: [fullstart] auto [centerstart] repeat(10, minmax(min-content, 14.4rem)) [centerend] auto [fullend]; }

.uploadcontainer {
  background-color: #FFFFFF;
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: [fullstart] auto [centerstart] repeat(10, minmax(min-content, 14.4rem)) [centerend] auto [fullend]; }

.nav {
  list-style: none;
  padding-left: 20%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12rem, max-content));
  grid-column-gap: 10px; }
  @media only screen and (max-width: 81.25em) {
    .nav {
      padding-left: 10%; } }
  @media only screen and (max-width: 75em) {
    .nav {
      padding-left: 5rem; } }
  @media only screen and (max-width: 68.75em) {
    .nav {
      grid-template-columns: repeat(auto-fit, minmax(12rem, max-content));
      padding-left: 20%; } }
  @media only screen and (max-width: 62.5em) {
    .nav {
      padding-left: 8%; } }
  @media only screen and (max-width: 56.25em) {
    .nav {
      padding-left: 16%; } }
  @media only screen and (max-width: 43.75em) {
    .nav {
      display: none; } }
  .nav__link:link, .nav__link:active, .nav__link:visited {
    font-size: 1.6rem;
    font-family: Open Sans;
    color: #1a1a1a;
    text-decoration: none;
    display: block;
    text-align: center;
    padding: 1.5rem;
    background-color: #fef3e7;
    background-color: #FFFFFF;
    font-weight: 300; }
    @media only screen and (max-width: 75em) {
      .nav__link:link, .nav__link:active, .nav__link:visited {
        font-size: 1.4rem; } }
  .nav__link:hover {
    text-decoration: underline;
    text-decoration-color: #CBCBCD;
    text-decoration-style: solid;
    text-decoration-thickness: 2px; }

.logo {
  padding: 4rem;
  display: grid;
  justify-items: start; }
  @media only screen and (max-width: 56.25em) {
    .logo {
      justify-self: center; } }
  @media only screen and (max-width: 43.75em) {
    .logo {
      padding: 4rem;
      margin-right: 25%;
      margin-top: 5%; } }
  @media only screen and (max-width: 37.5em) {
    .logo {
      justify-self: start; } }
  .logo a:link,
  .logo a:active,
  .logo a:visited {
    outline: none; }
  .logo__img {
    width: 15rem;
    height: auto;
    display: block;
    object-fit: cover;
    border: none; }

.margin-bottom-small {
  margin-bottom: 2rem; }

.margin-bottom-med {
  margin-bottom: 4rem; }

.margin-bottom-mini {
  margin-bottom: 1.5rem; }

.margin-bottom-large {
  margin-bottom: 12rem; }

.heading-1 {
  font-family: Open Sans;
  font-size: 6rem;
  font-weight: 700;
  line-height: 1.4; }
  @media only screen and (max-width: 81.25em) {
    .heading-1 {
      font-size: 5.5rem; } }
  @media only screen and (max-width: 75em) {
    .heading-1 {
      font-size: 4.2rem; } }
  @media only screen and (max-width: 43.75em) {
    .heading-1 {
      font-size: 3.6rem; } }

.heading-2 {
  font-family: Libre Baskerville, serif;
  font-size: 3.6rem;
  font-weight: 700; }

.heading-2a {
  font-family: Open Sans;
  font-size: 3.4rem;
  font-weight: 700; }

.heading-2b {
  font-family: Open Sans;
  font-size: 4rem;
  font-weight: 700; }

.heading-3 {
  font-family: Open Sans;
  font-size: 1.8rem;
  font-weight: 700; }

.heading-4 {
  font-family: Open Sans;
  font-size: 1.8rem;
  font-weight: 600; }

.slide-header {
  font-family: Open Sans;
  font-size: 6rem;
  font-weight: 700;
  line-height: 1.4; }
  @media only screen and (max-width: 43.75em) {
    .slide-header {
      font-size: 4.5rem; } }
  @media only screen and (max-width: 31.25em) {
    .slide-header {
      font-size: 3.5rem; } }
  @media only screen and (max-width: 25em) {
    .slide-header {
      font-size: 3.5rem; } }

.heading-price {
  font-family: Open Sans;
  font-size: 3.6rem;
  font-weight: 700; }

.heading-blog {
  font-family: Open Sans;
  font-size: 3.6rem;
  font-weight: 700; }

.regular {
  font-size: 1.6rem;
  font-weight: 400;
  font-family: Open Sans; }

.regular2 {
  font-size: 1.6rem;
  font-weight: 300;
  font-family: Open Sans; }

.regular3 {
  font-size: 1.8rem;
  font-weight: 600;
  font-family: Open Sans; }

.regular4 {
  font-size: 1.6rem;
  font-weight: 400;
  font-family: Open Sans; }

.regular5 {
  font-size: 2.6rem;
  font-weight: 400;
  font-family: Open Sans; }

.med {
  font-size: 1.8rem;
  font-weight: 400;
  font-family: Open Sans; }

.semi-med {
  font-size: 2rem;
  font-weight: 400;
  font-family: Open Sans; }

.small {
  font-size: 1.4rem;
  font-weight: 400;
  font-family: Open Sans; }

.customheader1 {
  font-family: Open Sans;
  font-size: 1.8rem;
  font-weight: 700; }

.picon {
  width: 3rem; }

.pheader {
  font-family: Open Sans;
  font-size: 1.8rem;
  font-weight: 400; }

.pheader2 {
  font-family: Open Sans;
  font-size: 1.6rem;
  font-weight: 400;
  margin-right: 10%;
  padding-bottom: 2rem; }

.pprice {
  font-family: Open Sans;
  font-size: 3.6rem;
  font-weight: 600; }

.pdemo {
  font-size: 1.6rem;
  font-weight: 400;
  font-family: Open Sans;
  color: #1B75BC; }

.pstar::after {
  content: ' \2606';
  font-size: 2.5rem;
  color: #6C7F8E;
  font-family: Open Sans; }

.preview-small {
  font-size: 1.4rem;
  font-weight: 400;
  font-family: Open Sans;
  color: #111111; }

.features {
  font-size: 1.4rem;
  font-weight: 300;
  font-family: Open Sans; }

.pquantity {
  width: 10rem;
  height: 6rem;
  margin-right: 4rem;
  border: 1px solid #111111;
  border-radius: 5px;
  text-align: center;
  font-size: 2rem;
  padding: 1rem;
  margin-bottom: 2rem; }
  @media only screen and (max-width: 87.5em) {
    .pquantity {
      width: 9rem;
      height: 5rem;
      margin-right: 3rem; } }
  @media only screen and (max-width: 81.25em) {
    .pquantity {
      width: 8rem;
      height: 5rem;
      margin-right: 2.5rem; } }

.cart {
  width: 3rem;
  cursor: pointer; }

@media only screen and (max-width: 68.75em) {
  .navarrow {
    width: 6rem;
    height: 6rem; } }

.button {
  width: 33rem;
  height: 4.5rem;
  border-radius: 50px;
  font-family: Open Sans;
  font-size: 1.4rem;
  font-weight: 700;
  color: #FFFFFF;
  border: none;
  cursor: pointer;
  outline: none; }

.button-medium {
  width: 30rem;
  height: 5.5rem;
  border-radius: 10px;
  font-family: Open Sans;
  font-size: 1.4rem;
  font-weight: 700;
  color: #FFFFFF;
  border: none;
  cursor: pointer;
  outline: none; }

.button-small {
  width: 20rem;
  height: 5.5rem;
  border-radius: 10px;
  font-family: Open Sans;
  font-size: 1.6rem;
  font-weight: 400;
  color: #FFFFFF;
  border: none;
  cursor: pointer;
  outline: none; }

.button-custom {
  width: 20.2rem;
  height: 6rem;
  border: 3px solid #1B75BC;
  border-radius: 5px;
  background-color: #d2e8f9;
  color: #1B75BC;
  font-size: 1.8rem;
  cursor: pointer;
  outline: none; }

.button-blue {
  background-color: #1B75BC;
  font-weight: 400;
  font-size: 1.6rem; }

.button-orange {
  background-color: #F7941D; }

.button-orange-dark {
  background-color: #FF673E; }

.button-products {
  width: 30rem;
  height: 6rem;
  border-radius: 5px;
  margin-right: 2rem;
  color: #FFFFFF;
  font-size: 1.8rem;
  cursor: pointer;
  outline: none;
  border: none; }
  @media only screen and (max-width: 87.5em) {
    .button-products {
      width: 28rem;
      height: 5rem;
      margin-right: 1.5rem; } }
  @media only screen and (max-width: 81.25em) {
    .button-products {
      width: 20rem;
      height: 5rem;
      margin-right: 1.5rem; } }
  @media only screen and (max-width: 37.5em) {
    .button-products {
      width: 20rem;
      height: 6rem;
      margin-right: 10rem;
      font-size: 1rem;
      margin-bottom: 2rem; } }
  @media only screen and (max-width: 31.25em) {
    .button-products {
      width: 20rem;
      height: 6rem;
      margin-right: 10rem;
      font-size: 1rem;
      margin-bottom: 2rem; } }
  @media only screen and (max-width: 25em) {
    .button-products {
      width: 20rem;
      height: 6rem;
      margin-right: 10rem;
      font-size: 1rem;
      margin-bottom: 2rem; } }

.products-blue {
  background-color: #1B75BC; }

.products-orange {
  background-color: #F7941D; }

.products-orange-dark {
  background-color: #FF673E; }

.contactbutton {
  font-family: Open Sans;
  font-size: 1.6rem;
  font-weight: 600;
  width: 142px;
  height: 43px;
  background-color: #1B75BC;
  color: #FFFFFF;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  outline: none; }

.empbtn {
  font-family: Open Sans;
  font-size: 1.4rem;
  font-weight: 300;
  width: 196px;
  height: 43px;
  background-color: #F7941D;
  color: #FFFFFF;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  outline: none; }

.empbtn2 {
  font-family: Open Sans;
  font-size: 1.4rem;
  font-weight: 300;
  width: 195px;
  height: 43px;
  background-color: #1B75BC;
  color: #FFFFFF;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  outline: none; }

.empbtn3 {
  font-family: Open Sans;
  font-size: 1.4rem;
  font-weight: 300;
  width: 196px;
  height: 43px;
  background-color: #1B75BC;
  color: #FFFFFF;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  outline: none; }
